import logo from "./milly.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p style={{ fontSize: 30, margin: 0 }}>Text</p>
        <p style={{ fontSize: 50, margin: 0, margin: 20, marginBottom: 40 }}>
          07902166169
        </p>
        <img
          src={logo}
          style={{
            borderRadius: 500,
            border: "1px solid #fff",
            boxShadow: "0px 0px 20px gold",
          }}
          className="App-logo"
          alt="logo"
        />
        <p style={{ fontSize: 30, margin: 0, marginTop: 30, marginBottom: 10 }}>
          on WhatsApp... to book a...
        </p>
        <p style={{ fontSize: 100, margin: 10 }}>£1000!!!!</p>
        <p style={{ fontSize: 30, margin: 10 }}>-worth make-up class! 😱🤯🙊</p>
      </header>
    </div>
  );
}

export default App;
